<template>
  <v-card class="mt-5" flat>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Tipo de Cliente</td>
            <td v-if="cliente.tipoCliente === '0'">Empresa</td>
            <td v-if="cliente.tipoCliente === '1'">Persona Física</td>
          </tr>
          <tr v-if="cliente.tipoCliente === '1'">
            <td>Apellido</td>
            <td>{{ cliente.apellido || '-' }}</td>
          </tr>
          <tr v-if="typeof cliente !== 'undefined' && cliente.tipoCliente === '1'">
            <td>Nombre</td>
            <td>{{ cliente.nombre || '-' }}</td>
          </tr>
          <tr v-if="cliente.tipoCliente === '0'">
            <td>Razon Social</td>
            <td>{{ cliente.razonSocial || '-' }}</td>
          </tr>
          <tr v-if="cliente.tipoCliente === '1'">
            <td>DNI</td>
            <td>{{ cliente.dni || '-' }}</td>
          </tr>
          <tr v-if="cliente.tipoCliente === '0'">
            <td>CUIT</td>
            <td>{{ cliente.cuit || '-' }}</td>
          </tr>
          <tr v-if="typeof operatoria.sectorProductivo !== 'undefined'">
            <td>Sector Productivo</td>
            <td>{{ operatoria.sectorProductivo.nombre || '-' }}</td>
          </tr>
          <tr>
            <td>Domicilio Electrónico</td>
            <td>{{ cliente.domicilioElectronico || '-' }}</td>
          </tr>
          <tr>
            <td>Domicilio Legal</td>
            <td>{{ cliente.domicilioLegal || '-' }}</td>
          </tr>
          <tr>
            <td>Domicilio Real</td>
            <td>{{ cliente.domicilioReal || '-' }}</td>
          </tr>
          <tr>
            <td>Nro. Contacto</td>
            <td>{{ cliente.celular || '-' }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
export default {
  props: {
    cliente: Object,
    operatoria: Object,
  },
};
</script>
