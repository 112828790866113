<template>
<div>
    <v-simple-table>
        <template v-slot:default>
        <tbody>
            <tr>
            <td>Nombre</td>
            <td>{{ operatoria.nombre || '-'}}</td>
            </tr>
            <tr>
            <td>Descripción</td>
            <td>{{ operatoria.descripcion || '-'}}</td>
            </tr>
            <tr v-if='typeof(operatoria.sectorProductivo) !== "undefined"'>
            <td>Sector Productivo</td>
            <td>{{ operatoria.sectorProductivo.nombre || '-'}}</td>
            </tr>
            <tr v-for='p in clienteParametro' v-bind:key="p.id">
              <td>{{p.tipo_parametro_id.nombre}}</td>
              <td>{{p.valor}}</td>
            </tr>
            <tr>
            <td>Reglamento</td>
            <td>
            <v-btn>Descargar</v-btn>
            </td>
            </tr>
            <tr>
            <!--<td>Imagen</td>
            <td><v-img class='img-fluid'
            :src='operatoria.imagenURL'
            aspect-ratio='1'></v-img></td> -->
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</div>
</template>
<script>
import { API_URL } from '@/common/config';

const axios = require('axios');

export default {
  props: {
    operatoria: Object,
  },
  data: () => ({
    clienteParametro: [],
    token: localStorage.getItem('token'),
  }),
  methods: {
    async getClienteParametros() {
      const url = `${API_URL}api/clienteParametros`;
      const header = {
        headers: {
          token: this.token,
        },
      };
      const data = {
        lineaCreditoCliente_id: this.$route.params.solicitud_id,
      };
      await axios.post(url, data, header).then((response) => {
        this.clienteParametro = response.data.ClienteParametro;
        if (response.data.status === 'error') {
          console.log('error al obtener parametros del cliente');
        } else {
          console.log(this.clienteParametro);
        }
      });
    },
  },
  created() {
    this.getClienteParametros();
  },
};
</script>
