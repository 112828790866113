<template>
  <v-container>
    <v-row>
      <v-breadcrumbs :items="bread" large></v-breadcrumbs>
    </v-row>
    <v-row>
      <v-col lg="12">
        <v-card>
          <v-card-title class="text-center justify-center py-6">
            <h3 class="font-weight-bold display-1">Administrar {{ this.operatoria.nombre }}</h3>
          </v-card-title>

          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tab href="#cliente"> Cliente </v-tab>
            <v-tab href="#credito"> Solicitud </v-tab>
            <v-tab href="#documentacion"> Documentación </v-tab>
            <v-tab href="#comprobantes" v-if="solicitud.estado_id === 7 && operatoria.tipo_servicio_id === 13">
              Comprobantes de Pago
            </v-tab>
            <v-tab href="#bitacora"> Bitácora </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="cliente">
              <Cliente :cliente="cliente" :operatoria="operatoria"></Cliente>
            </v-tab-item>
            <v-tab-item value="credito">
              <Credito :operatoria="operatoria" :parametros="parametros"></Credito>
            </v-tab-item>
            <v-tab-item value="comprobantes">
              <ComprobantesPago></ComprobantesPago>
            </v-tab-item>
            <v-tab-item value="documentacion">
              <Documentacion :documentos="documentos"></Documentacion>
            </v-tab-item>
            <v-tab-item value="bitacora">
              <Bitacora :bitacora="bitacora"></Bitacora>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { API_URL } from '@/common/config';
import Bitacora from '../components/creditoView/Bitacora.vue';
import Cliente from '../components/creditoView/Cliente.vue';
import Credito from '../components/creditoView/Credito.vue';
import Documentacion from '../components/creditoView/Documentacion.vue';
import ComprobantesPago from './ComprobantesPago.vue';

const axios = require('axios');

export default {
  name: 'CreditoView',
  components: {
    Bitacora,
    Cliente,
    Credito,
    Documentacion,
    ComprobantesPago,
  },
  data: () => ({
    solicitud: {},
    operatoria: {},
    lineaCreditoId: '',
    lccId: '',
    cliente: {},
    documentos: [],
    parametros: [],
    clienteParametros: [],
    bitacora: [],
    token: localStorage.getItem('token'),
    tab: null,
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Ver crédito',
        disabled: true,
        href: '/catalogo',
      },
    ],
    tipoCredito: '',
  }),
  methods: {
    getSolicitud() {
      const url = `${API_URL}api/servicioClientes/${this.lccId}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        console.log('Solicitud obtenida');
        [this.solicitud] = response.data.lineaCreditoCliente;
        this.lineaCreditoId = this.solicitud.lineaCredito_id;
        this.getOperatoria();
        this.getCliente();
        this.getBitacora();
        this.getDocumentacion();
      });
    },
    getOperatoria() {
      console.log('Obteniendo datos de operatoria');
      const url = `${API_URL}api/servicio/${this.solicitud.lineaCredito_id}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        console.log('Operatoria obtenida');
        this.operatoria = response.data.lineaCredito;
        this.operatoria.bases = `${API_URL}lineas/${this.operatoria.bases}`;
        this.operatoria.imagenURL = `${API_URL}lineas/${this.operatoria.imagenURL}`;
        this.getParametros();
      });
    },

    getCliente() {
      const url = `${API_URL}api/clientes/${localStorage.getItem('cliente_id')}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        this.cliente = response.data.cliente;
        const { user } = response.data;

        if (user) {
          this.cliente.celular = this.cliente.celular ? this.cliente.celular : user.telefono;
          this.cliente.domicilioElectronico =
            this.cliente.domicilioElectronico === null ? user.email : this.cliente.domicilioElectronico;
        }
      });
    },

    verDoc(url) {
      window.open(url);
    },

    getDocumentacion() {
      // Verifico si es un Fideicomiso o una Linea de Credito
      // eslint-disable-next-line
      // const url = `${API_URL}api/documentacion/solicitud/${this.solicitud.id}/${this.operatoria.id}`;

      try {
        if (this.lineaCreditoId === undefined || this.lccId === undefined) {
          return;
        }
        const url = `${API_URL}api/documentacionClientes/servicio/${this.lineaCreditoId}/${this.lccId}`;
        const header = {
          headers: {
            'Content-Type': 'application/json',
            token: this.token,
          },
        };
        console.log('Obteniendo documentacion');
        axios.get(url, header).then((response) => {
          console.log('Documentacion obtenida');
          if (response.data.status !== 'error') {
            this.documentos = response.data.listaDocumentosCliente.filter((el) => el.documento_maestro);
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    getBitacora() {
      console.log('Obteniendo bitacora');
      const url = `${API_URL}api/bitacora/${this.solicitud.id}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        console.log('Bitacora obtenida');
        const { bitacora } = response.data;
        this.bitacora = bitacora ? bitacora.reverse() : [];
      });
    },
    getParametros() {
      console.log('Obteniendo parametros operatoria');
      const url = `${API_URL}api/servicioParametros/operatoria/${this.operatoria.id}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios.get(url, header).then((response) => {
        console.log('Parametros obtenidos');
        this.parametros = response.data.parametros;
        this.parametros = this.parametros.filter((p) => p.tipo_parametro_id.destino === 'operatoria');
      });
    },
  },

  created() {
    this.lccId = this.$route.params.solicitud_id;
    this.getSolicitud();
  },
};
</script>
