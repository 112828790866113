<template>
  <v-card flat class="mt-5">
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Documento Original</th>
              <th class="text-left">Documento Cliente</th>
              <th class="text-left">Estado</th>
              <th class="text-left">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in documentos" :key="item.original.id">
              <td v-if="typeof item.original !== 'undefined'">
                {{ item.original.nombre }}
              </td>
              <td v-if="typeof item.cliente !== 'undefined'">
                {{ item.cliente.nombre || ' - ' }}
              </td>
              <td>
                <v-icon v-if="item.cliente.estado === 1" color="warning" icon="mdi-check" size="x-large">mdi-close</v-icon>
                <v-icon v-if="item.cliente.estado === 2" color="success" icon="mdi-check" size="x-large">mdi-check</v-icon>
              </td>
              <td>
                <div class="d-flex justify-space-between">
                  <v-btn :disabled="!item.cliente.id" v-on:click="verDoc(item.cliente.url)" color="success">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    documentos: Array,
  },
  methods: {
    verDoc(url) {
      window.open(url);
    },
  },
};
</script>
